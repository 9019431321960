import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'users')) {
          console.log('fetchUsers')
          console.log(qvars)
          const { forceRefresh } = qvars
          store.state.users.fetchingUserList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()

          let totalItems = 0

          const resortUsers = thUsers => {
            // sort the items
            let theSortBy = qvars.sortBy
            if (theSortBy === 'user') {
              theSortBy = 'last_name'
            }
            if (theSortBy === '') {
              theSortBy = 'last_name'
            }
            if (qvars.sortDesc) {
              thUsers.sort((a, b) => {
                console.log('sort anything desc')
                // console.log(a)
                // console.log(b)
                // console.log(theSortBy)
                return b[theSortBy].localeCompare(a[theSortBy])
              })
            } else {
              thUsers.sort((a, b) => {
                console.log('sort anything asc')
                // console.log(a)
                // console.log(b)
                // console.log(theSortBy)
                return a[theSortBy].localeCompare(b[theSortBy])
              })
            }
          }
          const paginateUsers = theUsers2 => {
            const start = (qvars.page - 1) * qvars.perPage
            const end = start + qvars.perPage
            return theUsers2.slice(start, end)
          }
          const resortFilterUsers = users => {
            const resultUsers = []
            // first apply filters
            users.forEach(item => {
              let addThisItem = true
              if (qvars.role !== null && item.role !== qvars.role) {
                addThisItem = false
              }
              console.log('resortFilterUsers')
              console.log(qvars.onboarding)
              console.log(item)
              if (qvars.region !== null && item.region_loc !== qvars.region) {
                addThisItem = false
              }
              if (qvars.onboarding !== null && item.is_onboarding !== qvars.onboarding) {
                addThisItem = false
              }
              if (qvars.status !== null && item.status !== qvars.status) {
                addThisItem = false
              }
              if (qvars.q !== null
                && !item.username.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.cognito_group.includes(qvars.q.toUpperCase())
                && !item.first_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.last_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.client_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.email.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.role.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.status.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (addThisItem) {
                resultUsers.push(item)
              }
            })
            totalItems = resultUsers.length
            // resort users
            resortUsers(resultUsers)
            // Apply pagination and return
            return paginateUsers(resultUsers)
          }

          // Check for caching
          let doRefresh = false
          if (forceRefresh) {
            doRefresh = true
          } else if (qvars.client_name === '' && (localStorage.getItem('users') === null || parseInt(localStorage.getItem('users_lastRefresh'), 10) + 1440000 < timeDiff)) { // cache for 24 hours
            doRefresh = true
          } else if (qvars.client_name !== '' && (localStorage.getItem(`${qvars.clientname}-users`) === null || parseInt(localStorage.getItem(`${qvars.clientname}-users_lastRefresh`), 10) + 1440000 < timeDiff)) { // cache for 24 hours
            doRefresh = true
          }
          if (doRefresh) {
            console.log('Refreshing Users')
            const apiName = 'APIGateway'
            let path = '/users'
            if (qvars.client_name !== '') {
              path = `/users?client_name=${qvars.client_name}`
            }
            const myInit = { response: true }

            API.get(apiName, path, myInit)
              .then(response => {
                const theResponse = response
                const users = response.data
                users.forEach(element => {
                  // eslint-disable-next-line no-param-reassign
                  element.fullName = `${element.first_name} ${element.last_name}`
                  // eslint-disable-next-line no-param-reassign
                  element.role = `${element.cognito_group}`

                  if (element.is_active === true) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'active'
                  } else if (element.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'disabled'
                  }
                })
                // Store User data in local storage
                if (qvars.client_name !== '') {
                  localStorage.setItem(`${qvars.client_name}-users`, JSON.stringify(users))
                  localStorage.setItem(`${qvars.client_name}-users_lastRefresh`, d1.getTime())
                } else {
                  localStorage.setItem('users', JSON.stringify(users))
                  localStorage.setItem('users_lastRefresh', d1.getTime())
                }
                theResponse.data.users = resortFilterUsers(users)
                theResponse.data.totalUsers = totalItems
                // console.log(theResponse)
                store.state.users.fetchingUserList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.users.fetchingUserList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Users Data')
            const theResponse = { data: {} }
            let userData = []
            if (qvars.client_name !== '') {
              userData = JSON.parse(localStorage.getItem(`${qvars.client_name}-users`))
            } else {
              userData = JSON.parse(localStorage.getItem('users'))
            }
            theResponse.data.users = resortFilterUsers(userData)
            theResponse.data.totalUsers = totalItems
            // console.log(theResponse)
            store.state.users.fetchingUserList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchClientRelatedUsers(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'users')) {
          console.log('fetchClientRelatedUsers')
          // console.log(qvars)
          let totalItems = 0

          const resortUsers = thUsers => {
            // sort the items
            const theSortBy = 'last_name'
            thUsers.sort((a, b) => {
              console.log(theSortBy)
              return b[theSortBy].localeCompare(a[theSortBy])
            })
          }
          const paginateUsers = theUsers2 => {
            const start = 0
            const end = theUsers2.length
            return theUsers2.slice(start, end)
          }
          const resortFilterUsers = users => {
            const resultUsers = []
            // first apply filters
            users.forEach(item => {
              resultUsers.push(item)
            })
            totalItems = resultUsers.length
            // resort users
            resortUsers(resultUsers)
            // Apply pagination and return
            return paginateUsers(resultUsers)
          }

          const apiName = 'APIGateway'
          let path = '/users'
          if (qvars.client_name !== '') {
            path = `/users/${qvars.client_name}`
          }
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              const theResponse = response
              const users = response.data
              users.forEach(element => {
                // eslint-disable-next-line no-param-reassign
                element.fullName = `${element.first_name} ${element.last_name}`
                // eslint-disable-next-line no-param-reassign
                element.role = `${element.cognito_group}`

                if (element.is_active === true) {
                  // eslint-disable-next-line no-param-reassign
                  element.status = 'active'
                } else if (element.is_active === false) {
                  // eslint-disable-next-line no-param-reassign
                  element.status = 'disabled'
                }
              })
              theResponse.users = resortFilterUsers(users)
              theResponse.totalUsers = totalItems
              // console.log(theResponse)
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    fetchUser(ctx, username) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'users')) {
          const apiName = 'APIGateway'
          // console.log(username)
          const path = `/user/${username}`
          // const path = '/user/'
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              // console.log(response)
              const theResponse = response
              const userData = {}
              userData.value = response.data
              // console.log(userData.value)
              // eslint-disable-next-line no-param-reassign
              userData.value.fullName = `${userData.value.first_name} ${userData.value.last_name}`
              // eslint-disable-next-line no-param-reassign
              userData.value.role = `${userData.value.cognito_group}`

              if (userData.value.is_active === true) {
                // eslint-disable-next-line no-param-reassign
                userData.value.status = 'active'
              } else if (userData.value.is_active === false) {
                // eslint-disable-next-line no-param-reassign
                userData.value.status = 'disabled'
              }
              // console.log(userData.value)
              theResponse.data = userData.value
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        if (ability.can('create', 'users')) {
          const apiName = 'APIGateway'
          const path = '/user'

          API.post(apiName, path, { body: userData })
            .then(response => {
              // Add new user to the local storage to avoid call to backend
              const newUsers = JSON.parse(localStorage.getItem('users'))
              if (newUsers !== null) {
                newUsers.push(userData)
                localStorage.setItem('users', JSON.stringify(newUsers))
              }
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'users')) {
          const apiName = 'APIGateway'
          const path = '/user'

          API.patch(apiName, path, { body: userData })
            .then(response => {
              // Update template in the local storage to avoid call to backend
              try {
                const oldUsers = JSON.parse(localStorage.getItem('users'))
                const newUsers = []
                if (userData.is_active === true) {
                  // eslint-disable-next-line no-param-reassign
                  userData.status = 'active'
                } else if (userData.is_active === false) {
                  // eslint-disable-next-line no-param-reassign
                  userData.status = 'disabled'
                }

                oldUsers.forEach(item => {
                  if (item.username === userData.username) {
                    newUsers.push(userData)
                  } else {
                    newUsers.push(item)
                  }
                })
                localStorage.setItem('users', JSON.stringify(newUsers))
              } catch (e) {
                console.log(e)
              }
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    deleteUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        if (ability.can('delete', 'users')) {
          const apiName = 'APIGateway'
          const path = '/user'

          API.del(apiName, path, { body: userData })
            .then(response => {
              // Update template in the local storage to avoid call to backend
              const oldUsers = JSON.parse(localStorage.getItem('users'))
              const newUsers = []
              oldUsers.forEach(item => {
                if (item.username !== userData.username) {
                  newUsers.push(item)
                }
              })
              localStorage.setItem('users', JSON.stringify(newUsers))
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    resetUserPassword(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'users')) {
          const apiName = 'APIGateway'
          const path = `/user/${qvars.username}/passwordtemp`
          const finalqvars = {
            temp_pswd_reset: qvars.temp_pswd_reset,
          }

          API.post(apiName, path, { body: finalqvars })
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    sendCognitoIdentityId(ctx, sendCognitoIdentityId) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'users')) {
          const apiName = 'APIGateway'
          // console.log(username)
          const path = `/user/message?target=${sendCognitoIdentityId}`
          // const path = '/user/'
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
  },
}

<template>
  <div>
    <b-card v-if="$can('read', 'users')">

      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="4"
          lg="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="userData.avatar"
              :text="avatarText(userData.fullName)"
              :variant="`light-${resolveUserRoleVariant(userData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.fullName }}
                </h4>
              </div>
              <div
                v-if="$can('update', 'users')"
                class="d-flex flex-wrap"
              >
                <b-button
                  :to="{ name: 'apps-users-edit', params: { username: userData.username } }"
                  variant="primary"
                  class="mr-1 mb-1"
                >
                  Edit
                </b-button>
                <b-button
                  variant="primary"
                  class="mb-1"
                  @click="handleResetPasswordClick"
                >
                  Reset Password
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="4"
          lg="4"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th
                class="pb-50"
                style="min-width: 120px;"
              >
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Username</span>
              </th>
              <td class="pb-50">
                {{ userData.username }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  v-if="userData.status"
                  icon="UserCheckIcon"
                  class="mr-75"
                />
                <feather-icon
                  v-else
                  icon="UserXIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.status }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ArrowUpRightIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Onboarding</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.is_onboarding }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UsersIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Client</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.client_display_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.role }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="GlobeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Region</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.region_loc }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="MailIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Email</span>
              </th>
              <td>
                <b-link
                  :href="'mailto:'+userData.email"
                >
                  {{ formatPhoneNumber(userData.email) }}
                </b-link>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Phone</span>
              </th>
              <td>
                <b-link
                  :href="'tel:'+userData.phone"
                >
                  {{ formatPhoneNumber(userData.phone) }}
                </b-link>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Account</span>
              </th>
              <td>
                {{ userData.cognito_status }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <!-- Reset Password Modal -->
    <b-modal
      id="rpmodal"
      ref="rpmodal"
      ok-title="Reset Password"
      cancel-variant="outline-secondary"
      cancel-title="Cancel"
      centered
      size="lg"
      title="New Content Available"
      @close="showTPModal = false"
    >
      Are you sure you wish to reset {{ userData.fullName }}'s password?
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ABILITY_TOKEN } from '@casl/vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BLink,
  },
  // inject: {
  //   $ability: { from: ABILITY_TOKEN },
  // },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    let canResetPassword = false
    if (props.userData.cognito_status === 'FORCE_CHANGE_PASSWORD') {
      canResetPassword = true
    }
    // const { can } = $ability.useAbility()
    // console.log(can('update', 'users'))
    const formatPhoneNumber = phone => {
      if (typeof phone !== 'undefined' && phone.length === 10) return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
      if (typeof phone !== 'undefined' && phone.length === 11) return `${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 11)}`
      return phone
    }
    return {
      avatarText,
      formatPhoneNumber,
      resolveUserRoleVariant,
      canResetPassword,
    }
  },
  data() {
    return {
      randomPWD: '',
    }
  },
  methods: {
    htmlToVNode(html) {
      return [
        this.$createElement('div', { domProps: { innerHTML: html } }),
      ]
    },
    handleResetPasswordClick() {
      console.log('handleResetPasswordClick')
      console.log(this.userData.username)
      // Open a modal confirmation
      if (this.$ability.can('update', 'users')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you wish to reset ${this.userData.fullName}'s password`, {
            title: 'Reset Password',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Reset',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.randomPWD = Math.random().toString(36).slice(-8)
              this.randomPWD = `${this.randomPWD}T4$`
              const qvars = {
                username: this.userData.username,
                temp_pswd_reset: this.randomPWD,
              }
              store
                .dispatch('app-user/resetUserPassword', qvars)
                .then(response => {
                  console.log(response)
                  this.$bvModal
                    .msgBoxConfirm(this.htmlToVNode(`Please provide ${this.userData.fullName} with the new temporary password: <strong>${this.randomPWD}</strong> <br><br>NOTE: The user must login and provide a new password with 48 hours.`), {
                      title: 'Password Reset successfully',
                      html: true,
                      size: 'sm',
                      okVariant: 'primary',
                      okTitle: 'Ok',
                      hideHeaderClose: false,
                      centered: true,
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error Resetting Password',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
  },
}
</script>

<style>

</style>

<template>
  <b-card
    v-if="$can('read', 'users')"
    no-body
  >
    <b-card-header class="pb-50">
      <h5>
        Permissions
      </h5>
    </b-card-header>
    <b-table
      striped
      responsive
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(module)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardHeader, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BCard, BTable, BFormCheckbox, BCardHeader,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    let abilitiesOptions = store.state.appSettings.abilities_options
    const permissionsData = []
    let tmp = {}
    // Remove Developer abilities if in staging or production
    if (process.env.VUE_APP_ENVIRONMENT_NAME === 'staging' || process.env.VUE_APP_ENVIRONMENT_NAME === 'production') {
      abilitiesOptions.forEach((item, index) => {
        if (item.subject === 'developer') {
          abilitiesOptions.splice(index, 1)
        }
      })
    }
    const abilitiesOptionsTmp = []
    // Filter out non-applicable permissions for client viewer and client admins
    if (props.userData.cognito_group === 'Client_Admin' || props.userData.cognito_group === 'Client_Viewer') {
      abilitiesOptions.forEach(item => {
        if (item.subject === 'users' || item.subject === 'clients' || item.subject === 'cameras') {
          abilitiesOptionsTmp.push(item)
        }
      })
      abilitiesOptions = [...abilitiesOptionsTmp]
    }

    abilitiesOptions.forEach(item => {
      tmp = { module: item.subject }
      tmp.read = false
      tmp.create = false
      tmp.update = false
      tmp.delete = false
      if (typeof props.userData.abilities !== 'undefined' && props.userData.abilities.length > 0) {
        props.userData.abilities.forEach(item2 => {
          if (item2.subject === item.subject) {
            item2.actions.forEach(item3 => {
              if (item3.action === 'read') {
                tmp.read = true
              } else if (item3.action === 'create') {
                tmp.create = true
              } else if (item3.action === 'update') {
                tmp.update = true
              } else if (item3.action === 'delete') {
                tmp.delete = true
              }
            })
          }
        })
      }
      permissionsData.push(tmp)
    })

    return {
      permissionsData,
    }
  },
}
</script>

<style>

</style>
